import React, { useRef, useState, useEffect } from 'react'
import {
  faCheck,
  faTimes,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from '../api/axios'
import { Link, useNavigate } from 'react-router-dom'
import './Register.css'
import eyeIcon from '../assets/eye_icon.svg'
import closedEyeIcon from '../assets/closed_eye_icon.svg'
import userRegIcon from '../assets/user_reg_icon.svg'
import { PASSWORD_REGEX, PASSWORD_HINT } from '../utils/validation'

const PWD_REGEX = PASSWORD_REGEX

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const NAME_PATTERN = /^(?:[А-Я][а-яёА-Я]{0,30}$)|(?:[А-Я][а-яёА-Я]*(?:[ -][А-Я][а-яёА-Я]{0,30})+$)/

const Register = () => {
  // const userRef = useRef()
  const navigate = useNavigate()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [lastName, setLastName] = useState('')
  const [validLastName, setValidLastName] = useState(false)
  const [lastNameFocus, setLastNameFocus] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [validFirstName, setValidFirstName] = useState(false)
  const [firstNameFocus, setFirstNameFocus] = useState(false)

  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [matchPassword, setMatchPassword] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState(false)

  useEffect(() => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      navigate('/admin/upload', { replace: true })
    }
  }, [navigate])

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const MailIcon = () => (
    <svg
      className="input-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
  )

  const LockIcon = () => (
    <svg
      className="input-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-7h-1V7c0-2.76-2.24-5-5-5S7 4.24 7 7v3H6c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm-6 0H9V7c0-1.65 1.35-3 3-3s3 1.35 3 3v3z" />
    </svg>
  )

  // useEffect(() => {
  //   userRef.current.focus()
  // }, [])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  useEffect(() => {
    setValidLastName(NAME_PATTERN.test(lastName))
    setValidFirstName(NAME_PATTERN.test(firstName))
  }, [lastName, firstName])

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password))
    setValidMatch(password === matchPassword && matchPassword.length > 0)
  }, [password, matchPassword])

  useEffect(() => {
    setErrMsg('')
  }, [email, lastName, firstName, password, matchPassword])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const v2 = PWD_REGEX.test(password)
    const v3 = EMAIL_REGEX.test(email)
    if (!v2 || !v3 || !validLastName || !validFirstName) {
      setErrMsg('Invalid Entry')
      return
    }
    try {
      await axios.post(
        '/api/register',
        JSON.stringify({
          email,
          last_name: lastName,
          first_name: firstName,
          password,
          password_confirm: matchPassword,
          role: 'куратор'
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      )
      // console.log(response?.data)
      // console.log(response?.data.jwt)
      // console.log(JSON.stringify(response))
      setSuccess(true)
      setEmail('')
      setLastName('')
      setFirstName('')
      setPassword('')
      setMatchPassword('')
    } catch (err) {
      // console.log(err.response.data.message)
      alert(
        err.response && err.response.data.message
          ? err.response.data.message
          : err
      )
      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 409) {
        setErrMsg('Пользователь с указанным адресом электронной почты уже существует')
      } else {
        setErrMsg('Registration Failed')
      }
      // errRef.current.focus()
    }
  }

  return (
    <>
      {success
        ? (
        <section className="container success-registration">
          <h3>Регистрация прошла успешно!</h3>
          <p>
            <Link to="/admin/login">Войти</Link>
          </p>
        </section>
          )
        : (
        <div className="container-form">
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          {/* <h1>Регистрация</h1> */}
          <form onSubmit={handleSubmit}>

            {/* Lastname Input */}
            <div className="input-container">
              <img src={userRegIcon} className='input-icon' alt='User'/>
              <input
                // ref={userRef}
                type="text"
                id="lastName"
                autoComplete="off"
                className="input-field"
                placeholder="Фамилия"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                aria-invalid={!validFirstName.toString()}
                onFocus={() => setLastNameFocus(true)}
                onBlur={() => setLastNameFocus(false)}
              />
              <FontAwesomeIcon icon={validLastName ? faCheck : faTimes} className={`icon ${validLastName ? 'valid' : 'invalid'}`} />
            </div>
            {lastNameFocus && !validLastName && (
                <p id="lnote" className="instructions">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Фамилия должна начинаться с заглавной буквы и может содержать до 30 русских букв, допускается использование дефиса или пробела для составных фамилий.
                </p>
            )}

            {/* Firstname Input */}
            <div className="input-container">
              <img src={userRegIcon} className='input-icon' alt='user' />
              <input
                // ref={userRef}
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Имя"
                autoComplete="off"
                className="input-field"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
                aria-invalid={!validFirstName.toString()}
                onFocus={() => setFirstNameFocus(true)}
                onBlur={() => setFirstNameFocus(false)}
              />
              <FontAwesomeIcon icon={validFirstName ? faCheck : faTimes} className={`icon ${validFirstName ? 'valid' : 'invalid'}`} />
            </div>
            {firstNameFocus && !validFirstName && (
                <p id="fnote" className="instructions">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Имя должно начинаться с заглавной буквы и может содержать до 30 русских букв, допускается использование дефиса или пробела для составных имен.
                </p>
            )}

            {/* Email Input */}
            <div className="input-container">
              <MailIcon />
              <input
                type="email"
                id="email"
                // ref={userRef}
                autoComplete="off"
                className="input-field"
                placeholder="Почта"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-invalid={validEmail ? 'false' : 'true'}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
              />
              <FontAwesomeIcon
                icon={validEmail ? faCheck : faTimes}
                className={`status-icon ${validEmail ? 'valid' : 'invalid'}`}
              />
            </div>
            <p
              id="uidnote"
              className={
                emailFocus && email && !validEmail
                  ? 'instructions'
                  : 'offscreen'
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Некорректный email
            </p>

            {/* Password Input */}
            <div className="input-container">
              <LockIcon />
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                className="input-field"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                aria-invalid={validPassword ? 'false' : 'true'}
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
              />
              <img
                src={passwordVisible ? eyeIcon : closedEyeIcon}
                alt="Toggle Password Visibility"
                className="togglePasswordVisibilityIcon"
                onClick={togglePasswordVisibility}
              />
              <FontAwesomeIcon
                icon={validPassword ? faCheck : faTimes}
                className={`status-icon ${validPassword ? 'valid' : 'invalid'}`}
              />
            </div>
            <p
              id="pwdnote"
              className={
                passwordFocus && !validPassword ? 'instructions' : 'offscreen'
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              {PASSWORD_HINT}
            </p>

            {/* Confirm Password Input */}
            <div className="input-container">
              <LockIcon />

              <input
                type={passwordVisible ? 'text' : 'password'}
                id="confirm_pwd"
                className="input-field"
                placeholder="Подтвердите пароль"
                value={matchPassword}
                onChange={(e) => setMatchPassword(e.target.value)}
                required
                aria-invalid={validMatch ? 'false' : 'true'}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
              />
              <img
                src={passwordVisible ? eyeIcon : closedEyeIcon}
                alt="Toggle Password Visibility"
                className="togglePasswordVisibilityIcon"
                onClick={togglePasswordVisibility}
              />
              <FontAwesomeIcon
                icon={validMatch ? faCheck : faTimes}
                className={`status-icon ${validMatch ? 'valid' : 'invalid'}`}
              />
            </div>
            <p
              id="confirmnote"
              className={
                matchFocus && !validMatch ? 'instructions' : 'offscreen'
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Должно соответствовать первому полю ввода пароля.
            </p>

            <button
              type="submit"
              className="button"
              disabled={!validEmail || !validLastName || !validFirstName || !validPassword || !validMatch}
            >
              Зарегистрироваться
            </button>
          </form>
          <p>
            Уже зарегистрированы?
            <br />
            <Link to="/admin/login" className="link">
              Войти
            </Link>
          </p>
        </div>
          )}
    </>
  )
}

export default Register
