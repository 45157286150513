import React, { useState, useEffect } from 'react'
import axios from '../../api/axios'
import styles from './RoleChanger.module.css'
import ConfirmationModal from '../ConfirmModal'

const RoleChanger = ({ userUuid, usersRole, setSelectedUsersRole, fetchData, onClose }) => {
  const [newRole, setNewRole] = useState(usersRole)
  const [loading, setLoading] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setNewRole(usersRole)
    setIsChanged(false)
  }, [usersRole])

  const handleRoleChange = async () => {
    setLoading(true)
    try {
      await axios.put(`/api/admin/v2/user/${userUuid}/promote`, null, {
        params: { role: newRole }
      })
      setSelectedUsersRole(newRole)
      fetchData()
    //   onClose()
    } catch (error) {
      const message = error.response?.data?.message || 'Произошла ошибка'
      alert(message)
    } finally {
      setLoading(false)
      setIsModalOpen(false)
    }
  }

  const handleSelectChange = (e) => {
    const selectedRole = e.target.value
    setNewRole(selectedRole)
    setIsChanged(selectedRole !== usersRole)
  }

  return (
    <>
      <select
        value={newRole}
        onChange={handleSelectChange}
        disabled={loading}
        className={styles['role-select']}
      >
        <option value="куратор">Куратор</option>
        <option value="автор">Автор</option>
        <option value="клиент">Клиент</option>
      </select>

      <button
        onClick={() => setIsModalOpen(true)}
        disabled={loading || !isChanged}
        className={`${styles['role-change-button']} ${isChanged ? styles['active-button'] : ''}`}
      >
        {loading ? 'Загрузка...' : 'Изменить роль'}
      </button>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleRoleChange}
      >
        <p>Вы уверены, что хотите изменить роль пользователя?</p>
      </ConfirmationModal>
    </>
  )
}

export default RoleChanger
