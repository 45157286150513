import React, { useState, useEffect } from 'react'
import styles from './ChangePasswordForm.module.css'
import axios from '../api/axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import eyeIcon from '../assets/eye_icon.svg'
import closedEyeIcon from '../assets/closed_eye_icon.svg'
import { PASSWORD_REGEX, PASSWORD_HINT } from '../utils/validation'

const ChangePasswordForm = ({ onClose }) => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [matchNewPassword, setMatchNewPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [validNewPassword, setValidNewPassword] = useState(false)
  const [validMatch, setValidMatch] = useState(false)
  const [validPassword, setValidPassword] = useState(false)
  const [newPasswordFocus, setNewPasswordFocus] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const PWD_REGEX = PASSWORD_REGEX

  useEffect(() => {
    setValidNewPassword(PWD_REGEX.test(newPassword))
    setValidMatch(matchNewPassword === newPassword && matchNewPassword.length > 0)
    setValidPassword(password.length > 0)
  }, [newPassword, matchNewPassword, password])

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!validNewPassword || !validMatch) {
      setError('Пожалуйста, проверьте свои записи.')
      return
    }

    try {
      await axios.put('/api/client/v1/user/password', {
        password,
        new_password: newPassword,
        confirm_new_password: matchNewPassword
      })
      setError('')
      setSuccess('Пароль успешно изменен!')
      setPassword('')
      setMatchNewPassword('')
      setNewPassword('')
      // onClose();
    } catch (err) {
      setSuccess('')
      setError(err.response && err.response.data.message
        ? 'Не удалось изменить пароль. Пожалуйста, попробуйте еще раз. ' + err.response.data.message
        : 'Не удалось изменить пароль. Пожалуйста, попробуйте еще раз.')
    }
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        {/* Current Password Input */}
        <div className={styles.inputContainer}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            name="password"
            placeholder="Текущий пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputField}
          />
          <img
            src={passwordVisible ? eyeIcon : closedEyeIcon}
            alt="Toggle Password Visibility"
            className={styles.togglePasswordVisibilityIcon}
            onClick={togglePasswordVisibility}
          />
        </div>

        {/* New Password Input */}
        <div className={styles.inputContainer}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="new_password"
            name="new_password"
            placeholder="Новый пароль"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            aria-invalid={validNewPassword ? 'false' : 'true'}
            className={styles.inputField}
            onFocus={() => setNewPasswordFocus(true)}
            onBlur={() => setNewPasswordFocus(false)}
          />
          <img
            src={passwordVisible ? eyeIcon : closedEyeIcon}
            alt="Toggle Password Visibility"
            className={styles.togglePasswordVisibilityIcon}
            onClick={togglePasswordVisibility}
          />
          <FontAwesomeIcon
            icon={validNewPassword ? faCheck : faTimes}
            className={`${styles.statusIcon} ${validNewPassword ? styles.valid : styles.invalid}`}
          />
        </div>
        <p
              id="pwdnote"
              className={
                newPasswordFocus && !validNewPassword ? 'instructions' : 'offscreen'
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              {PASSWORD_HINT}
            </p>

        {/* Confirm New Password Input */}
        <div className={styles.inputContainer}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="confirm_new_password"
            name="confirm_new_password"
            placeholder="Подтвердите новый пароль"
            value={matchNewPassword}
            // onChange={handleChange}
            onChange={(e) => setMatchNewPassword(e.target.value)}
            required
            aria-invalid={validMatch ? 'false' : 'true'}
            className={styles.inputField}
            onFocus={() => setMatchFocus(true)}
            onBlur={() => setMatchFocus(false)}
          />
          <img
            src={passwordVisible ? eyeIcon : closedEyeIcon}
            alt="Toggle Password Visibility"
            className={styles.togglePasswordVisibilityIcon}
            onClick={togglePasswordVisibility}
          />
          <FontAwesomeIcon
            icon={validMatch ? faCheck : faTimes}
            className={`${styles.statusIcon} ${validMatch ? styles.valid : styles.invalid}`}
          />
        </div>
        <p
              id="confirmnote"
              className={
                matchFocus && !validMatch ? 'instructions' : 'offscreen'
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Должно соответствовать полю ввода пароля.
            </p>

        {error && <p className={styles.errorMessage}>{error}</p>}
        {success && <p className={styles.successMessage}>{success}</p>}

        <button type="submit" className={styles.button}
        disabled={!validNewPassword || !validMatch || !validPassword}
        >
            Отправить
        </button>
      </form>

    </div>
  )
}

export default ChangePasswordForm
