import React, { Route, Routes } from 'react-router-dom'
import Register from './pages/Register'
import VideoUpload from './pages/VideoUpload'
import VideoList from './pages/VideoList'
import Login from './pages/Login'
import jwtInterceptor from './api/jwtInterceptor'
import ProtectedRoute from './routes/ProtectedRoute'
import UserList from './pages/UserList'
import UserDetail from './pages/UserDetail'
import Header from './components/Header'
import SharedVideo from './pages/SharedVideo'
import AdminNavbar from './components/AdminNavbar'
import HashtagList from './pages/HashtagList'
import EducationalContent from './pages/EducationalContent'
import MyProfile from './pages/Profile'
import { UserProvider } from './contexts/UserContext'
import NotFound from './pages/NotFound'
import ForgotPassword from './pages/ForgotPassword'
import Coupons from './pages/Coupons'

jwtInterceptor()

function App () {
  return (
    <main className="App">
      <UserProvider>
        <Routes>
          <Route
            path="/" element={
              <>
                <Header></Header>
                <Login />
              </>
            }
          />
          <Route path="/admin/login" element={
              <>
                <Header></Header>
                <Login />
              </>
            }
          />
          <Route
            path="/admin/forgot-password" element={
              <>
                <Header></Header>
                <ForgotPassword />
              </>
            }
          />
          <Route path="/admin/register" element={<>
                <Header></Header>
                <Register />
              </>} />
          <Route path="/sharedvideo/:id" element={<SharedVideo />} />
          <Route path="*" element={<NotFound />} />

          <Route
            path="/admin/coupons/*"
            element={
              <ProtectedRoute allowedRoles={['куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <Coupons />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/my-profile"
            element={
              <ProtectedRoute allowedRoles={['автор', 'куратор']}>
                <Header></Header>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/educational-content"
            element={
              <ProtectedRoute allowedRoles={['куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <EducationalContent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/hashtags"
            element={
              <ProtectedRoute allowedRoles={['автор', 'куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <HashtagList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/upload"
            element={
              <ProtectedRoute allowedRoles={['автор', 'куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <VideoUpload />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/videos"
            element={
              <ProtectedRoute allowedRoles={['автор', 'куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <VideoList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute allowedRoles={['куратор']} >
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <UserList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users/user/:id"
            element={
              <ProtectedRoute allowedRoles={['куратор']}>
                <Header></Header>
                <AdminNavbar></AdminNavbar>
                <UserDetail />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserProvider>
    </main>
  )
}

export default App
