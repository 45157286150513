import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from '../api/axios'
import tableStyles from './CouponsTable.module.css'

const columns = [
  { field: 'email', headerName: 'Email', flex: 1, minWidth: 120 },
  { field: 'userName', headerName: 'Имя пользователя', flex: 1, minWidth: 120 },
  { field: 'partnerName', headerName: 'Имя партнёра', flex: 1, minWidth: 120 },
  { field: 'uuid', headerName: 'ID (идентификатор)', flex: 1, minWidth: 120 },
  { field: 'discountName', headerName: 'Наименование скидки', flex: 1, minWidth: 120 },
  { field: 'discountValue', headerName: 'Размер скидки', maxWidth: 120, minWidth: 70 },
  { field: 'issueDate', headerName: 'Дата выдачи', width: 120 },
  { field: 'validTillDate', headerName: 'Срок активации', width: 120 },
  { field: 'activationDate', headerName: 'Дата активации', width: 120 }
]

const CustomToolbar = ({ onSearchChange, searchQuery }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter
        onChange={onSearchChange}
        value={searchQuery}
        placeholder="Поиск..."
      />
    </GridToolbarContainer>
  )
}

const CouponsTable = () => {
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('') // Состояние для отложенного обновления
  const [debounceTimeout, setDebounceTimeout] = useState(null) // Состояние для таймаута дебаунса
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const initialPage = parseInt(searchParams.get('page'), 10) || 1

  const [paginationModel, setPaginationModel] = useState({
    page: initialPage - 1,
    pageSize: 10
  })
  const [rowCount, setRowCount] = useState(0)

  const fetchCoupons = async () => {
    try {
      setLoading(true)
      const offset = paginationModel.page * paginationModel.pageSize
      let apiUrl = `/api/admin/v1/coupons?offset=${offset}&limit=${paginationModel.pageSize}`
      if (searchQuery && searchQuery.length >= 3) {
        apiUrl += `&query=${encodeURIComponent(searchQuery)}`
      }
      const response = await axios.get(apiUrl)
      const { count, coupons: couponsData } = response.data

      const formattedCoupons = couponsData
        ? couponsData.map(coupon => {
          const issueDate = coupon.issue_date ? new Date(coupon.issue_date).toLocaleDateString() : ''
          const validTillDate = coupon.valid_till_date ? new Date(coupon.valid_till_date).toLocaleDateString() : ''
          const currentDate = new Date()
          const activationDate = coupon.activation_date
            ? new Date(coupon.activation_date).toLocaleDateString()
            : (new Date(coupon.valid_till_date) < currentDate ? 'срок истек' : '')

          return {
            id: coupon.uuid,
            email: coupon.user.email,
            userName: `${coupon.user.first_name} ${coupon.user.last_name}`,
            partnerName: coupon.partner.display_name,
            uuid: coupon.uuid,
            discountName: coupon.discount_name,
            discountValue: `${coupon.discount_value}%`,
            issueDate,
            validTillDate,
            activationDate,
            isFutureDate: new Date(coupon.valid_till_date) > currentDate,
            isEmptyActivation: !coupon.activation_date,
            isActivePartner: coupon.partner.active
          }
        })
        : []

      setCoupons(formattedCoupons)
      setRowCount(count)
    } catch (error) {
      setError(error.response?.data?.message || 'Произошла ошибка')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchQuery.length === 0 || searchQuery.length >= 3) {
      fetchCoupons()
    }
  }, [paginationModel, searchQuery])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    params.set('page', paginationModel.page + 1)
    navigate(`?${params.toString()}`, { replace: true })
  }, [paginationModel.page, navigate, location.search])

  const handleSearchChange = (event) => {
    const query = event.target.value
    setInputValue(query) // Мгновенно обновляем значение в поле input

    if (debounceTimeout) {
      clearTimeout(debounceTimeout) // Очищаем предыдущий таймаут
    }

    const newTimeout = setTimeout(() => {
      setSearchQuery(query) // Обновляем searchQuery после задержки

      if (query.length >= 3 || query.length === 0) {
        setPaginationModel(prevModel => ({
          ...prevModel,
          page: 0 // Сброс на первую страницу при новом поиске
        }))
      }
    }, 1000) // Ожидаем 1 секунды перед обновлением searchQuery

    setDebounceTimeout(newTimeout) // Устанавливаем новый таймаут
  }

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout) // Очищаем таймер при размонтировании
      }
    }
  }, [debounceTimeout])

  const getRowClassName = (params) => {
    const { isFutureDate, isEmptyActivation, isActivePartner } = params.row
    return isFutureDate && isEmptyActivation && isActivePartner ? tableStyles.highlightRow : ''
  }

  return (
    <div style={{ width: '100%', margin: '2rem auto' }}>
      {error && <div className={tableStyles.error}>{error}</div>}
      <DataGrid
        rows={coupons}
        columns={columns}
        pageSize={paginationModel.pageSize}
        pageSizeOptions={[10, 20, 50]}
        pagination
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={loading}
        getRowClassName={getRowClassName}
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: {
            onSearchChange: handleSearchChange,
            searchQuery: inputValue // Передаем inputValue для мгновенного обновления
          }
        }}
        localeText={{
          noRowsLabel: 'Нет данных для отображения'
        }}
        sx={{
          '& .MuiDataGrid': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            '& p': {
              padding: '0 !important'
            }
          },
          '& .MuiDataGrid-overlayWrapper': {
            height: '2rem'
          }
        }}
      />
    </div>
  )
}

export default CouponsTable
