/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import DataTable from '../components/DataTable';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const limit = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");

  const query = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(query.get('page') || '1', 10);
  const zeroIndexedPage = pageFromUrl - 1;
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const fetchData = async () => {
    try {
      const offset = zeroIndexedPage * limit;
      let apiUrl = `/api/admin/v1/users?offset=${offset}&limit=${limit}`;
        if (searchQuery && searchQuery.length >= 3) {
            apiUrl += `&query=${encodeURIComponent(searchQuery)}`;
        }
      const response= await axios.get(apiUrl);
      setUsers(response.data.users);
      setTotalCount(response.data.count);
      setLastSearchQuery(searchQuery);
    } catch (error) {
      console.error('Ошибка при получении данных пользователей. ', error);
    }
  };

const handleSearchChange = (event) => {
  const query = event.target.value
  setInputValue(query)
  if (debounceTimeout) {
    clearTimeout(debounceTimeout)
  }

  const newTimeout = setTimeout(() => {
    setSearchQuery(query)
    if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
            navigate("?page=1");
    }
  }, 1000)

  setDebounceTimeout(newTimeout)
};

// Добавляем useEffect для очистки таймера при размонтировании компонента
useEffect(() => {
  return () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  };
}, [debounceTimeout]);

  useEffect(() => {
    if (searchQuery.length === 0 || searchQuery.length >= 3) {
      fetchData();
    }
  }, [zeroIndexedPage, searchQuery]);

  const handleChangePage = (event, value) => {
    navigate(`?page=${value}`);
  };

  const totalPages = Math.ceil(totalCount / limit);

  const DataGridRows = Array.isArray(users)
    ? users.map(user => ({
      uuid: user.uuid,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      email: user.email,
      // time: '00:00:00',
      // lastTime: '00:00'
    }))
    : [];

  const filteredUsers = DataGridRows.filter(user =>
    user.firstName.toLowerCase().includes(search.toLowerCase()) ||
    user.lastName.toLowerCase().includes(search.toLowerCase()) ||
    user.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="users">
      <div className='container'>
        <div className="paginationContainer">
          <Pagination
            count={totalPages}
            page={zeroIndexedPage + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
        <div className='users-search-container'>
          <div className='users-search-icon'>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
              >
                <path
                    fill="black"
                    d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16a6.468 6.468 0 0 0 4.23-1.392l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                ></path>
              </svg>
          </div>
          <input
              type="text"
              maxLength={49}
              placeholder="Поиск..."
              value={inputValue}
              onChange={handleSearchChange}
              className='users-search-input'
          />
        </div>
        {filteredUsers.length > 0 ? <DataTable usersData={filteredUsers}  offset={zeroIndexedPage * limit} limit={limit} fetchData={fetchData} /> : 'Нет данных'}
      </div>
    </div>
  );
};

export default UserList;