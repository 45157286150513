import React, { useState, useEffect, useRef } from 'react'
import axios from '../api/axios'
import styles from './DiscountForm.module.css'

const DiscountForm = ({ onClose, onDiscountCreated, onDiscountUpdated, initialData, discountUuid }) => {
  const [formData, setFormData] = useState({
    name: '',
    partner_uuid: '',
    value: '',
    conditions: '',
    description: ''
  })
  const [partners, setPartners] = useState([])
  const [error, setError] = useState('')
  const conditionsRef = useRef(null)
  const descriptionRef = useRef(null)

  const autoResizeTextarea = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get('/api/admin/v1/partners')
        setPartners(response.data.partners)
      } catch (error) {
        setError(error.response?.data?.message || 'Произошла ошибка при загрузке партнёров')
      }
    }

    fetchPartners()

    if (initialData) {
      setFormData({
        name: initialData.name || '',
        partner_uuid: initialData.partner_uuid || '',
        value: initialData.value || '',
        conditions: initialData.conditions || '',
        description: initialData.description || ''
      })
    }
  }, [initialData])

  useEffect(() => {
    if (conditionsRef.current) autoResizeTextarea(conditionsRef.current)
    if (descriptionRef.current) autoResizeTextarea(descriptionRef.current)
  }, [formData.conditions, formData.description])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    autoResizeTextarea(e.target)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      const selectedPartner = partners.find(partner => partner.uuid === formData.partner_uuid)
      const partnerName = selectedPartner ? selectedPartner.display_name : '1'

      const data = {
        name: formData.name,
        partner_uuid: formData.partner_uuid,
        value: parseFloat(formData.value),
        conditions: formData.conditions,
        description: formData.description
      }

      let response
      if (discountUuid) {
        response = await axios.put(`/api/admin/v1/discount/${discountUuid}`, data)
      } else {
        response = await axios.post('/api/admin/v1/discount', data)
      }

      const responseData = {
        partnerName,
        ...response.data
      }

      if (discountUuid) {
        onDiscountUpdated(responseData)
      } else {
        onDiscountCreated(responseData)
      }

      onClose()
    } catch (error) {
      setError(error.response?.data?.message || 'Произошла ошибка')
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.discountForm}>
      {!discountUuid && <h3 className={styles.title}>Новая скидка</h3>}
      <div className={styles.formGroup}>
        <label htmlFor="partner_uuid">Партнёр*</label>
        {discountUuid
          ? (
            <div className={styles.partnerName} value={formData.partner_uuid}>{initialData.partnerName}</div>
            )
          : (
            <select
              id="partner_uuid"
              name="partner_uuid"
              value={formData.partner_uuid}
              onChange={handleChange}
              required
              className={styles.partnerSelect}
            >
              <option value="">Выберите партнёра</option>
              {partners.map((partner) => (
                <option key={partner.uuid} value={partner.uuid}>
                  {partner.display_name}
                </option>
              ))}
            </select>
            )}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="name">Наименование скидки*</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="value">Размер скидки*</label>
        <input
          type="number"
          id="value"
          name="value"
          value={formData.value}
          onChange={handleChange}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="conditions">Условия*</label>
        <textarea
          id="conditions"
          name="conditions"
          value={formData.conditions}
          onChange={handleChange}
          required
          rows={3}
          maxLength={999}
          ref={conditionsRef}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="description">Описание*</label>
        <textarea
          className='formGroupText'
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          rows={3}
          maxLength={999}
          ref={descriptionRef}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.modalButtons}>
        <button type="button" className={styles.cancelButton} onClick={onClose}>Отмена</button>
        <button type="submit" className={styles.saveButton}>
          {discountUuid ? 'Обновить' : 'Сохранить'}
        </button>
      </div>
    </form>
  )
}

export default DiscountForm
