import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user, loading, setUser, setAvatar } = useUser()
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    if (!token) {
      setUser(null)
      setAvatar(null)
    }
  }, [token, setUser, setAvatar])

  if (loading) {
    return <div>Загрузка...</div>
  }

  if (!token) {
    return <Navigate to="/admin/login" replace={true} />
  }

  if (user && !allowedRoles.includes(user.role)) {
    return <Navigate to="/notfound" replace={true} />
  }

  return children
}

export default ProtectedRoute
