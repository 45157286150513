import axios from './axios'

const jwtInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwt')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem('jwt')
        // alert('Пожалуйста, авторизуйтесь снова.')
        // window.location.href = '/admin/login'
        if (!window.location.pathname.includes('/admin/login')) {
          alert('Пожалуйста, авторизуйтесь снова.')
          window.location.href = '/admin/login'
        }
      }
      return Promise.reject(error)
    }
  )
}

export default jwtInterceptor
