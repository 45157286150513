import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import styles from './PartnerForm.module.css'

const PartnerForm = ({ onClose, onPartnerCreated, onPartnerUpdated, initialData, partnerUuid }) => {
  const [formData, setFormData] = useState({
    display_name: '',
    official_name: '',
    contact_phone: '',
    contact_person: '',
    link: ''
    // inn: '',
  })

  const [error, setError] = useState('')

  useEffect(() => {
    if (initialData) {
      setFormData({
        display_name: initialData.partnerName || '',
        official_name: initialData.legalName || '',
        contact_phone: initialData.phone || '',
        contact_person: initialData.contactPerson || '',
        link: initialData.link || ''
        // inn: initialData.inn || '',
      })
    }
  }, [initialData])

  const normalizeUrl = (url) => {
    if (!url) return null
    const completeUrl = /^https?:\/\//i.test(url) ? url : `https://${url.replace(/^\/\//, '')}`
    return completeUrl
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')

    const normalizedLink = normalizeUrl(formData.link)
    const dataToSubmit = { ...formData, link: normalizedLink || '' }

    try {
      if (partnerUuid) {
        const response = await axios.put(`/api/admin/v1/partner/${partnerUuid}`, dataToSubmit)
        onPartnerUpdated(response.data)
      } else {
        const response = await axios.post('/api/admin/v1/partner', dataToSubmit)
        onPartnerCreated(response.data)
      }
      onClose()
    } catch (error) {
      setError(error.response?.data?.message || 'Произошла ошибка')
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.partnerForm}>
      <div className={styles.formGroup}>
        <label htmlFor="display_name">Партнёр*</label>
        <input
          type="text"
          id="display_name"
          name="display_name"
          value={formData.display_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="official_name">Наименование юр.лица партнёра*</label>
        <input
          type="text"
          id="official_name"
          name="official_name"
          value={formData.official_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="contact_phone">Телефон</label>
        <input
          type="tel"
          id="contact_phone"
          name="contact_phone"
          value={formData.contact_phone}
          onChange={handleChange}
          pattern="[0-9]*"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="contact_person">Контактное лицо</label>
        <input
          type="text"
          id="contact_person"
          name="contact_person"
          value={formData.contact_person}
          onChange={handleChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="link">Ссылка</label>
        <input
          type="text"
          id="link"
          name="link"
          value={formData.link}
          onChange={handleChange}
        />
      </div>
      {/* <div className={styles.formGroup}>
        <label htmlFor="inn">ИНН</label>
        <input
          type="number"
          id="inn"
          name="inn"
          value={formData.inn}
          onChange={handleChange}
          required
        />
      </div> */}
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.modalButtons}>
        <button type="button" className={styles.cancelButton} onClick={onClose}>
          Отмена
        </button>
        <button type="submit" className={styles.saveButton}>
          {partnerUuid ? 'Обновить' : 'Сохранить'}
        </button>
      </div>
    </form>
  )
}

export default PartnerForm
