import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from '../api/axios'

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt')
      if (!token) {
        setLoading(false)
        return
      }

      try {
        const userResponse = await axios.get('/api/admin/v1/user', {
          headers: { Authorization: `Bearer ${token}` }
        })
        setUser(userResponse.data)

        try {
          const avatarResponse = await axios.get('/api/admin/v1/avatar', {
            headers: { Authorization: `Bearer ${token}` }
          })
          setAvatar(avatarResponse.data)
        } catch (avatarError) {
          // console.warn('Аватар не найден:', avatarError)
          setAvatar(null) // Устанавливаем null, если аватара нет
        }
      } catch (userError) {
        // console.error('Ошибка при загрузке пользователя:', userError)
        // localStorage.removeItem('jwt')
        setUser(null)
        setAvatar(null)
      } finally {
        setLoading(false)
      }
    }

    fetchUser()
  }, [])

  return (
    <UserContext.Provider value={{ user, avatar, setUser, setAvatar, loading }}>
      {children}
    </UserContext.Provider>
  )
}
